import axiosConn from '../axiosConn';

const useUpdatePostViews = (post, currentPlayer) => {
  const updatePostViews = async () => {
    if (!post || typeof post !== 'object') {
      return;
    }
    try {
      const res = await axiosConn.put(
        `/posts/${post._id}/view`,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentPlayer.token}`,
          },
        },
      );
      //   console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return updatePostViews;
};

export default useUpdatePostViews;
