import secureLocalStorage from 'react-secure-storage';
import { saveLocationData } from './apiUtils'; // Import the new utility function

export const getLocationAndSave = async (
  setLatitude,
  setLongitude,
  setAccuracy,
  setTimestamp,
  // setViewport,
  player,
) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
  };

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude, accuracy } = position.coords;
        const timestamp = position.timestamp;

        setLatitude(latitude);
        setLongitude(longitude);
        setAccuracy(accuracy);
        setTimestamp(timestamp);
        // setViewport((prevViewport) => ({
        //   ...prevViewport,
        //   latitude,
        //   longitude,
        // }));

        // console.log(latitude, longitude);
        secureLocalStorage.setItem('latitude', JSON.stringify(latitude));
        secureLocalStorage.setItem('longitude', JSON.stringify(longitude));

        try {
          await saveLocationData(
            latitude,
            longitude,
            accuracy,
            timestamp,
            player.token,
          );
        } catch (err) {
          console.error('Error saving location:', err.message);
        }

        resolve(position);
      },
      (error) => {
        console.error('Error getting location:', error);
        reject(error);
      },
      options,
    );
  });
};
