import axios from 'axios';

const getAddressFromCoordinates = async (latitude, longitude) => {
  const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  // console.log(longitude, latitude);
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`;

  try {
    const response = await axios.get(url);
    const results = response.data.features;

    if (results.length > 0) {
      return results[0].place_name;
    } else {
      throw new Error('No address found for the given coordinates.');
    }
  } catch (error) {
    console.error('Error fetching address:', error);
    throw error;
  }
};

export default getAddressFromCoordinates;
