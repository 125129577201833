// utils/getActivePlayers.js
import { differenceInDays } from 'date-fns';

const getActivePlayers = (players) => {
  const today = new Date();
  return players.filter((player) => {
    const lastLoginDate = new Date(player.lastLogin);
    const daysDifference = differenceInDays(today, lastLoginDate);
    return daysDifference <= 30;
  });
};

export default getActivePlayers;
