import React, { useState, useEffect, useContext, memo, useRef } from 'react';
import { Search, Room } from '@material-ui/icons';
import ReactMapGL, {
  MapboxGL,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Marker,
  Popup,
} from 'react-map-gl';
// import mapboxgl from '!mapbox-gl';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
// import mapboxgl from '!mapbox-gl';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';
import Topbar from '../../components/topbar/Topbar';
import TopbarPlayerMap from '../../components/topbarPlayerMap/TopbarPlayerMap';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { getLocationAndSave } from '../../utils/locationUtil';
import { useGetPosts } from '../../hooks/useGetPosts';
import arePropsEqual from '../../arePropsEqual';
import Post from '../../components/post/Post';
import { useTheme } from '../../context/ThemeContext';
import preciseFormat from '../../utils/preciseFormat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useMobileView from '../../hooks/useMobileView';
import useUpdatePostViews from '../../hooks/useUpdatePostViews';
import { useLocation } from 'react-router-dom';

import './playermap.css';

export default memo(function PlayerMapPage() {
  const { player } = useContext(AuthContext);
  const mobileView = useMobileView();
  const [latitude, SetLatitude] = useState(null);
  const [longitude, SetLongitude] = useState(null);
  const [accuracy, SetAccuracy] = useState(null);
  const [timestamp, SetTimestamp] = useState(null);
  const [radius, setRadius] = useState(5000);
  const [unit, setUnit] = useState('mi');
  const [playersWithin, setPlayersWithin] = useState({});
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [zoom, setZoom] = useState(4);
  const [getPosition, setGetPosition] = useState(true);
  const initialViewport = useRef(null);
  //   const [address, SetAddress] = useState('');
  const [viewport, setViewport] = useState({
    latitude: 25.774772,
    longitude: -80.185942,
    zoom: 4,
    width: '100vw',
    height: '100vh',
    // center: [longitude, latitude],
  });
  const [page, setPage] = useState(1);
  const [postItems, setPostItems] = useState([]);
  const [socketPostIds, setSocketPostIds] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const lat = parseFloat(queryParams.get('lat'));
    const lon = parseFloat(queryParams.get('lon'));
    const parent = queryParams.get('parent');

    if (parent === 'post' && !isNaN(lat) && !isNaN(lon)) {
      SetLatitude(lat);
      SetLongitude(lon);
    }
  }, [location.search, queryParams]);

  const error = () => {
    console.log('error geolocation called.');
    setGetPosition(false);
  };

  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  const { isLoading, data, status } = useGetPosts(
    onSuccess,
    onError,
    player,
    null,
    page,
    'posts',
    null,
    // trigger,
  );

  // Reset page to 1 whenever playerId changes
  useEffect(() => {
    setPage(1);
    setPostItems([]);
  }, [player.data.player.playerId]);

  useEffect(() => {
    let mounted = true;
    if (status === 'success' && mounted && data) {
      // Check that data is defined
      if (data.length > 0) {
        setPostItems((prevPosts) => {
          const existingPostIds = prevPosts.map((post) => post._id);
          const newPosts = data.filter(
            (post) =>
              !existingPostIds.includes(post._id) &&
              !socketPostIds.includes(post._id), // Add socketPostIds logic
          );
          // return [...prevPosts, ...newPosts].sort(
          //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          // );

          return [...prevPosts, ...newPosts].sort((a, b) => {
            const dateA = a.repostedAt
              ? new Date(a.repostedAt)
              : new Date(a.createdAt);
            const dateB = b.repostedAt
              ? new Date(b.repostedAt)
              : new Date(b.createdAt);
            return dateB - dateA;
          });
        });
      } else {
        console.log('No more galaxy posts to load.');
      }
    }
    return () => {
      mounted = false;
    };
  }, [status, data, socketPostIds]); // Add socketPostIds to the dependency array

  useEffect(() => {
    let mounted = true;
    const options = {
      enableHighAccuracy: true,
    };
    const getData = async () => {
      if (!mounted) return;

      // if (queryParams && !isNaN(latitude) && !isNaN(longitude)) {
      //   // If lat, lon, and parent query parameters are present, set the viewport to these coordinates
      //   setViewport((prevViewport) => ({
      //     ...prevViewport,
      //     latitude: latitude,
      //     longitude: longitude,
      //     zoom: 6, // Adjust zoom level as needed
      //   }));
      //   console.log('There are queryParams so setting Viewport');
      //   return;
      // }
      await getLocationAndSave(
        SetLatitude,
        SetLongitude,
        SetAccuracy,
        SetTimestamp,
        // setViewport,
        player,
      ).then(async () => {
        const latitude = JSON.parse(secureLocalStorage.getItem('latitude'));
        const longitude = JSON.parse(secureLocalStorage.getItem('longitude'));
        // console.log(latitude, longitude);
        // console.log(radius);
        try {
          if (
            !latitude ||
            !longitude ||
            !getPosition ||
            radius < 0 ||
            radius === ''
          ) {
            console.log(
              'null latitude and longitude or location not shared or radius not positive',
            );
            return;
          }
          const res = await axiosConn.get(
            `/players/players-within/${radius}/center/${latitude},${longitude}/unit/${
              unit ? unit : 'mi'
            }`,
            {
              headers: {
                Authorization: `Bearer ${player.token}`,
              },
            },
          );
          // console.log(
          //   res.data.data.data,
          // .filter((playerWithin) =>
          //   player.data.player.friends.includes(playerWithin._id),
          // ),
          // );
          if (!mounted) return;
          setPlayersWithin(
            res.data.data.data,
            // .filter((playerWithin) =>
            //   player.data.player.friends.includes(playerWithin._id),
            // ),
          );
          // console.log(res.data.data.data);
          secureLocalStorage.setItem(
            'playersWithin',
            JSON.stringify(res.data.data.data),
          );

          // Store the initial viewport if not already set
          if (!initialViewport.current) {
            console.log(
              'Initial viewport not set',
              initialViewport.current,
              latitude,
              longitude,
            );
            const newViewport = {
              latitude,
              longitude,
              zoom: 4,
              width: '100vw',
              height: '100vh',
            };
            initialViewport.current = newViewport;
            setViewport(newViewport);
          }
        } catch (err) {
          console.log(err);
        }
      });
      // Schedule the next update
      setTimeout(getData, 5000); // 5 seconds
    };
    getData();
    return () => {
      mounted = false;
      // secureLocalStorage.setItem('playersWithin', null);
    };
  }, [latitude, longitude, player.token, radius, unit, playersWithin.length, player.data.player.friends, getPosition]);

  //   console.log(latitude, longitude, accuracy, timestamp);

  const playersWithin2 = JSON.parse(
    secureLocalStorage.getItem('playersWithin'),
  );
  // console.log(playersWithin2);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === 'Escape') {
        setSelectedPlayer(null);
      }
    };
    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    const updateViewport = () => {
      const latitude = JSON.parse(secureLocalStorage.getItem('latitude'));
      const longitude = JSON.parse(secureLocalStorage.getItem('longitude'));
      setViewport((prevViewport) => ({
        ...prevViewport,
        latitude,
        longitude,
      }));
    };

    updateViewport();
  }, [latitude, longitude]);

  const filteredPostItems = postItems.filter(
    (post) =>
      post.location &&
      (post.location.address ||
        (Array.isArray(post.location.coordinates) &&
          post.location.coordinates.length === 2 &&
          typeof post.location.coordinates[0] === 'number' &&
          typeof post.location.coordinates[1] === 'number')),
  );

  // console.log(filteredPostItems);
  const getOffsetCoordinates = (latitude, longitude, index) => {
    const offset = 0.0001; // Offset value for staggering markers
    const latOffset =
      latitude + (index % 2 === 0 ? offset : -offset) * (index + 1);
    const lonOffset =
      longitude + (index % 2 === 0 ? -offset : offset) * (index + 1);
    return [latOffset, lonOffset];
  };
  const handleDeletePost = (postId) => {
    setPostItems((prevPostItems) =>
      prevPostItems.filter((post) => post._id !== postId),
    );
  };

  const filteredPostsComponents = filteredPostItems.map((post, index) => {
    const [latOffset, lonOffset] = getOffsetCoordinates(
      post.location.coordinates[1],
      post.location.coordinates[0],
      index,
    );
    return (
      <Marker key={post._id} longitude={lonOffset} latitude={latOffset}>
        <button
          className="marker-btn2"
          onClick={(e) => {
            e.preventDefault();
            setSelectedPost(post);
          }}
          style={{
            position: 'relative',
            border: 'none',
            padding: '0',
            cursor: 'pointer',
            background: 'none',
            width: '50px',
            height: '70px',
          }}
          aria-label={`Post by ${post.playerId}`}
          title={`Post by ${post.playerId} "${post.desc.trim()}"`}
        >
          {post.img &&
          (post.img.endsWith('.mp4') || post.img.endsWith('.mov')) ? (
            <video
              src={post.img}
              autoPlay
              muted
              loop
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: 1,
              }}
            />
          ) : (
            <div
              style={{
                backgroundImage: `url(${
                  post.img ||
                  'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
              }}
            />
          )}
        </button>
      </Marker>
    );
  });
  // console.log(filteredPostItems);

  return (
    <>
      <div>
        <Topbar parent={'PlayerMap'} />
        <TopbarPlayerMap
          radius={radius}
          setRadius={setRadius}
          unit={unit ? unit : 'mi'}
          setUnit={setUnit}
          parent={'PlayerMap'}
          setZoom={setZoom}
          getPosition={getPosition}
        />
      </div>
      {/* {mobileView && <TopbarNotification />} */}
      <div className="full">
        <ReactMapGL
          {...viewport}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          mapStyle="mapbox://styles/joegalaxydotnet/clmquop4a04vw01phe5fi2sdq"
          onMove={(event) => {
            setViewport(event.viewport);
          }}
          // zoom={zoom}
        >
          <NavigationControl
            onViewportChange={(event) => {
              setViewport(event.viewport);
            }}
            position="top-left"
            // visualizePitch={true}
          />
          <GeolocateControl
            position="top-left"
            trackUserLocation={true}
            showUserHeading={true}
            showUserLocation={true}
            showAccuracyCircle={true}
            onTrackUserLocationStart={(evt) => {
              // console.log(evt);
              //setLatitude
              //setLongitude
            }}
          />
          <FullscreenControl position="top-left" />
          <ScaleControl
            unit={unit === 'mi' || unit === '' ? 'imperial' : 'metric'}
          />
          <Link
            to={`/profile/${player.data.player?.playerId}`}
            className="mmenu_header hover3"
          >
            <Marker latitude={latitude} longitude={longitude}>
              <button className="marker-btn2">
                <img
                  // src="https://res.cloudinary.com/joegalaxy/image/upload/v1695177384/Map_marker_fymyi0.svg"
                  src={
                    player.data.player?.profilePhoto
                      ? player.data.player?.profilePhoto
                      : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                  }
                  alt={player.data.player?.playerId}
                  title={player.data.player?.playerId}
                />
              </button>
            </Marker>
          </Link>
          {/* SetMyOnlineFriends(myFriends.filter((f) =>
          onlinePlayers.includes(f._id))); */}
          {/* playersWithin2.map((player) => ( */}
          {getPosition &&
            playersWithin2?.length !== 0 &&
            playersWithin2 !== null &&
            playersWithin2
              .sort((a, b) => a.jgkTokens - b.jgkTokens)
              .filter((playerWithin) =>
                player.data.player.friends.includes(playerWithin._id),
              )
              .map((player, index) => (
                <Link
                  to={`/profile/${player?.playerId}`}
                  className="mmenu_header hover3"
                  key={player._id}
                >
                  <Marker
                    key={player._id}
                    longitude={player.lastLocation.coordinates[0]}
                    latitude={player.lastLocation.coordinates[1]}
                  >
                    <button
                      className="marker-btn2"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedPlayer(player);
                      }}
                    >
                      <img
                        //   src="https://res.cloudinary.com/joegalaxy/image/upload/v1695177384/Map_marker_fymyi0.svg"
                        src={
                          player.profilePhoto
                            ? player.profilePhoto
                            : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                        }
                        alt={player.playerId}
                        title={player.playerId}
                      />
                    </button>
                  </Marker>
                </Link>
              ))}
          {filteredPostsComponents}
          {selectedPost ? (
            <Popup
              longitude={selectedPost.location.coordinates[0]}
              latitude={selectedPost.location.coordinates[1]}
              onClose={() => {
                setSelectedPost(null);
              }}
              // style={{
              //   backgroundColor: darkTheme ? 'black' : 'white',
              //   color: darkTheme ? 'white' : 'black',
              //   padding: '5px',
              //   borderRadius: '8px',
              // }}
            >
              <div
              // className={`popup-inner ${darkTheme ? 'darkTheme' : ''}`}
              >
                <Link
                  to={`/post/${selectedPost._id}`}
                  className="mmenu_header hover3 no-underline black-link"
                >
                  <div className="flex-column">
                    <div className="padding-bottom postTop">
                      <div className="postTopLeft">
                        {/* <Link to={`/profile/${player?.data.player.playerId}`}>
                          <img
                            className={
                              !mobileView
                                ? 'postProfileImg'
                                : 'postProfileImgMobile'
                            }
                            src={
                              player.data.player.profilePhoto
                                ? player.data.player.profilePhoto
                                : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                            }
                            alt=""
                            loading="lazy"
                          />
                        </Link> */}
                        {/* <span
                        className={
                          !mobileView ? 'postPlayerId' : 'postPlayerIdMobile'
                        }
                      >
                        {player.data.player.playerId}
                      </span> */}
                        {/* <div
                          className={
                            !mobileView ? 'postViews' : 'postViewsMobile'
                          }
                        >
                          <VisibilityIcon
                            sx={{ color: 'grey', fontSize: 15 }}
                          />
                          {views}
                        </div> */}
                        <span
                          className={
                            !mobileView ? 'postDate' : 'postDateMobile'
                          }
                        >
                          {preciseFormat(selectedPost?.createdAt)}
                        </span>
                        {selectedPost?.isPogw ? (
                          !mobileView ? (
                            <span className="pogwTitleSpan">
                              Proof of Great Work
                            </span>
                          ) : (
                            <span className="pogwTitleSpanMobile">POGW</span>
                          )
                        ) : null}
                      </div>
                      <div className="postTopRight"></div>
                    </div>
                    <div className="centered-text">
                      <h2>{selectedPost.playerId}</h2>
                      <p>"{selectedPost.desc}"</p>
                      <p>
                        {selectedPost?.location.address
                          ? selectedPost.location.address
                          : selectedPost.location?.coordinates
                          ? `Lat: ${selectedPost.location.coordinates[1]}, Lon: ${selectedPost.location.coordinates[0]}`
                          : 'Location not available'}
                      </p>{' '}
                    </div>
                  </div>
                </Link>
              </div>
              {selectedPost.img &&
              (selectedPost.img.endsWith('.mp4') ||
                selectedPost.img.endsWith('.mov')) ? (
                <video
                  width="100%"
                  src={selectedPost.img}
                  alt={selectedPost.desc}
                  title={selectedPost.desc}
                  controls
                  autoPlay
                  muted={false}
                />
              ) : (
                <img
                  width="100%"
                  src={
                    selectedPost.img
                      ? selectedPost.img
                      : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                  }
                  alt={selectedPost.desc}
                />
              )}
            </Popup>
          ) : null}
          {selectedPlayer ? (
            <Popup
              longitude={selectedPlayer.lastLocation.coordinates[0]}
              latitude={selectedPlayer.lastLocation.coordinates[1]}
              onClose={() => {
                setSelectedPlayer(null);
              }}
            >
              <div>
                <Link
                  to={`/profile/${selectedPlayer?.playerId}`}
                  className="mmenu_header hover3"
                >
                  <h2>{selectedPlayer.playerId}</h2>
                  <p>{selectedPlayer.desc}</p>
                </Link>
              </div>
              {selectedPlayer.coverPhoto &&
              (selectedPlayer.coverPhoto.endsWith('.mp4') ||
                selectedPlayer.coverPhoto.endsWith('.mov')) ? (
                <video
                  width="100%"
                  src={selectedPlayer.coverPhoto}
                  alt={selectedPlayer.playerId}
                  title={selectedPlayer.playerId}
                  controls
                  autoPlay
                  muted={false}
                />
              ) : (
                <img
                  width="100%"
                  src={
                    selectedPlayer.coverPhoto
                      ? selectedPlayer.coverPhoto
                      : 'https://gp1.wac.edgecastcdn.net/802892/http_public_production/profile_cover_photos/images/1242299/original/crop:x0y0w1920h746/quality:90/hash:1465227418/A-Screen_shot_2016-05-13_at_4.30.51_PM_copy.png?1465227418'
                  }
                  alt={selectedPlayer.playerId}
                />
              )}
            </Popup>
          ) : null}
        </ReactMapGL>
      </div>
    </>
  );
}, arePropsEqual);
// return await Promise.resolve(
//   //get latitude, longitude
//   navigator.geolocation.getCurrentPosition((position) => {
//     // console.log(position);
//     if (!mounted) return;

//     SetLatitude(position.coords.latitude);
//     SetLongitude(position.coords.longitude);
//     SetAccuracy(position.coords.accuracy);
//     SetTimestamp(position.timestamp);
//     setViewport({
//       latitude: position.coords.latitude,
//       longitude: position.coords.longitude,
//     });

//     secureLocalStorage.setItem(
//       'latitude',
//       JSON.stringify(position.coords.latitude),
//     );
//     secureLocalStorage.setItem(
//       'longitude',
//       JSON.stringify(position.coords.longitude),
//     );

//     try {
//       //only update if new coordinates
//       if (latitude && longitude) {
//         // const url = `http://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
//         // fetch(url)
//         //   .then((response) => response.json())
//         //   .then((data) =>
//         //     console.log(
//         //       data,
//         //       data.address?.city,
//         //       data.address?.region,
//         //       data.address?.road,
//         //       data.address?.state,
//         //       data.address?.postcode,
//         //     ),
//         //   );
//         axiosConn.put(
//           '/players/lastLocation',
//           {
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude,
//             accuracy: position.coords.accuracy,
//             timestamp: position.timestamp,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${player.token}`,
//             },
//           },
//         );
//       }
//       // else {
//       //   console.log(latitude, longitude);
//       // }
//     } catch (err) {
//       console.log(err.message);
//     }
//   }, error),
// )
